.sw-po-details-container {
  .po-details-header {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    .back-title {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .right-section {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .po-detail-approval-stage {
    margin-top: 10px;   
    display: grid;
    grid-template-columns: 8fr 2fr;
    gap: 10px;
    margin-top: 10px;
  }
  .form-container {
    padding: 10px;
    padding-bottom: 20px;
    background-color: white;
    .info-section {
      display: flex;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;
      .info-box {
        display: flex;
        flex-direction: column;
        gap: 15px;
        span:first-child {
          color: gray;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .add-additional-doc {
        max-width: 100%;
      }
    }
  }
  .add-additional-doc {
    max-width: 100%;
  }
  .attachments-container {
    background-color: white;
    padding: 10px;
  }
}
