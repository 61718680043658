@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.doc-container {
  .main-form-select {
    .css-1s2u09g-control {
      padding: 0 !important;
      padding-bottom: 3px !important;
    }
  }
  .cf-approval-warning.file-Upload-Warning {
    bottom: -35px;
  }
  max-width: 80%;
  margin: auto;
  display: flex;
  padding-right: 2em;
  padding-left: 2em;
  margin-top: 2em;
  flex-direction: column;
  .grid-con {
    display: flex;
    justify-content: center;
    margin-top: 4em;
    .button-div {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      margin-top: 2em;
      .button {
        background-color: #176a22;
      }
    }
  }
}
.input-con {
  margin-bottom: 2em;
  margin-right: 2em;
  .label {
    padding-bottom: 1em;

    margin: 0px;
    color: #008938;
  }
}
.button-container {
  display: flex;
  margin-bottom: 2em;
  margin-top: 3em;
  width: 100%;
  justify-content: center;
}
.file-name-con {
  display: flex;
  align-items: center;
  .del {
    color: red;
    cursor: pointer;
  }
}
.outlined-btn {
  color: #008938 !important;
  border-color: #008938 !important;
}
.details {
  display: flex;
  width: 100%;
  justify-content: space-between !important;
  align-items: flex-end;
  margin-top: 5em;
  grid-gap: 25px;
}
.password-input {
  margin-bottom: 2em;
}
.document-table {
  max-width: 99%;
  margin: auto;
  display: flex;
  padding: 2em;
  flex-direction: column;
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
.mainDocHeader {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3vw;
  margin-bottom: 2em;
  margin-top: 2em;
  align-items: center;
}
.docdateAndSelectContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 3vw;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
  }
}
.end-filters {
  margin-top: 25px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
}
.createDocButton {
  display: flex;
  justify-content: end;
  margin-right: 0.5em;
}
.cancel-div {
  margin-top: 1em;
  margin-right: 3em;
  display: flex;
  justify-content: flex-end;
}
