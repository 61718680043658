@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

// .login-container {
//   position: relative;
//   max-width: 1440px;
//   margin: auto;
//   display: flex;
//   @include respond-to(small) {
//     flex-direction: column;
//   }
//   .left {
//     width: 50%;
//     height: 100vh;
//     background: $bg-linear-blue;
//     border: 1px solid #000000;
//     box-sizing: border-box;
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     @include respond-to(small) {
//       width: 100%;
//       height: 30vh;
//     }
//     .img {
//       object-fit: contain;
//       height: inherit;
//       overflow: hidden;
//     }
//   }
//   .right {
//     width: 50%;
//     height: 100vh;
//     background: $bg-main;
//     display: flex;
//     align-items: center;
//     @include respond-to(small) {
//       width: 100%;
//       height: 70vh;
//     }
//     .login-form {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       grid-gap: 20px;
//       width: 420px;
//       margin: auto;
//       @include respond-to(small) {
//         width: auto;
//       }
//       .title {
//         font-style: normal;
//         font-weight: bold;
//         font-size: 48px;
//         line-height: 40px;
//         letter-spacing: 0.1px;
//         color: #373f41;
//       }
//     }
//   }
//   .BellcorpStudioLogo {
//     position: absolute;
//     right: 0;
//   }
// }
// backgroundColor: 'white',
// padding: '20px',
// borderRadius: '5px',
// boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
.login-background {
  background-image: url("../../resources/images/login-image.png");
  background-size: cover;
  min-height: 100vh;
  padding-top: 6rem;
  padding-left: 5rem;
  @include respond-to("small") {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .login-con {
    background-color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 400px;
    height: 500px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
    @include respond-to("small") {
      width: 80%;
      height: auto;
      min-height: 350px;
    }
    h1 {
      font-size: 2em;
      margin: 0.67em 0;
    }
  }
  .powered-by {
    color: white;
    margin-top: 30px;
    margin-left: 20px;
    font-weight: lighter;
  }
  .white-logo {
    position: absolute;
    top: 40%;
    right: 60px;
  }
}
