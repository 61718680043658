/** @format */
@import "../../../common-utilities/styles/variables.scss";
@import "../../../common-utilities/styles/responsive.scss";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  .logo {
    width: 180px;
    padding-left: 10px;
    @include respond-to("small") {
      width: 100px !important;
    }
  }
  .menu {
    display: flex;
  }
}
.logo-con {
  display: flex;
  align-items: center;
}
