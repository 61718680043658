/** @format */
@import "../../../common-utilities/styles/responsive.scss";
@import "../../../common-utilities/styles/variables.scss";

.past-tickets-container {
  margin: auto;
  padding: 0 60px;
  min-height: 85vh;
  .admin-create-new-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .case-filter-select {
    margin: 8px;
    max-width: 300px;
  }
  .create-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0px;
    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.1px;
      color: #373f41;
    }
    .l-c {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
    .mainPopper-container {
      position: relative;
      .create-new-popper {
        width: 100%;
        z-index: 3;
      }
    }
  }
  .nested-popper-container {
    position: relative;
  }

  .case-filter {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    align-items: center;
    row-gap: 10px;
    .case-time-picker {
      margin: 8px;
      position: relative;
      z-index: 1;
    }
    .case-addition-filter {
      width: 100%;
      margin: 8px;
      width: 300px;
      @include respond-to("small") {
        width: auto;
      }
    }
  }

  @media (max-width: 1300px) {
    .case-filter {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: 1010px) {
    .case-filter {
      grid-template-columns: 1fr;
    }
  }
  .other-si-row {
    // grid-template-columns: 0.5fr 0.5fr 0.6fr 1.5fr 1.5fr 1.5fr 1.5fr;
    // grid-template-columns: 0.5fr 0.5fr 0.6fr 0.6fr 0.6fr 0.6fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
    gap: 20px;
  }
  .admin-store-owner-row.si-row {
    // grid-template-columns: 0.5fr 0.5fr 0.6fr 1.5fr 1.5fr 1.5fr 1fr 1.5fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  .clickable {
    cursor: pointer;
    color: #306fff;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.subway-select__menu {
  z-index: 2;
}
